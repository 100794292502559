<template>
  <ion-page>
    <ion-content :fullscreen="true" :class="toEfir">
        <div class="base-stream">
            <video id="localInputVideo" :width="winWidth" :height="winHeight" autoplay muted playsinline></video>
        </div>
        <div class="remote-stream" id="remote">
        </div>
        <div class="control-head">
            <div class="return" on-touch="returnSelect" @click="returnSelect"><ion-icon src="/assets/icon/arrow-back.svg"></ion-icon></div>
            <div class="set-cam" on-touch="nextCam" @click="nextCam"><ion-icon src="/assets/icon/sync.svg"></ion-icon></div>
        </div>
    </ion-content>
      <ion-footer class="stream-start">
          <button type="button" on-touch="startStream" class="button-native ion-color button-type" :class="primary" @click="startStream">
              {{ butText }}
          </button>
      </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonIcon } from '@ionic/vue';
import {WebRtc} from "../lib/WebRTC";
import {useRouter} from "vue-router";
//import {Devices} from "../lib/Devices";
import store from '@/store'
import axios from "axios";

export default  {
    name: 'Stream',
    components: { IonContent, IonPage, IonIcon },
    data() {
        return {
            butText: 'Розпочати стрім',
            localStream: null,
            webRTC: null,
            devices: null,
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,

            remoteStream: [],
            status: 1,
            primary: 'ion-color-primary',
            user: null,
            toEfir: '',
            efir: false,
        }
    },
    setup() {
        const router = useRouter();
        return { router };
    },
    async mounted() {
        const self = this;
        window.addEventListener("orientationchange", function(event) {
            console.log(event);
            window.addEventListener("resize", function() {
                if(self.toEfir) {
                    self.winWidth = parseInt(window.innerWidth*0.3);
                    self.winHeight = parseInt(window.innerHeight*0.3);
                } else {
                    self.winWidth = window.innerWidth;
                    self.winHeight = window.innerHeight;
                }

                console.log(window.innerWidth, window.innerHeight);
            });
        });

        console.log(this.$refs);
        this.user = await store.dispatch('user/user');

        /*this.devices = new Devices();
        await this.devices.init();

        this.webRTC = new WebRtc('localInputVideo', {
            name: this.user.data.username,
            id: this.user.data.id,
            email: this.user.data.email,
            phone: this.user.data.phone,
        }, this, this.devices.getVideoDevice(), this.devices.getAudioDevice());*/

        var force_ = false;
        if(typeof this.$route.query.gp !== 'undefined')
            force_ = true;
        this.webRTC = new WebRtc('localInputVideo', {
            name: this.user.data.username,
            id: this.user.data.id,
            email: this.user.data.email,
            phone: this.user.data.phone,
            force: force_
        }, this, null, null);

        this.remoteStream = this.webRTC.remoteStream;

        console.log(this.user.data);
        if(typeof this.$route.query.gp !== 'undefined') {
            this.startStreamForce();
            var options = {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 0
            };
            if (navigator.geolocation) {
                navigator.geolocation.watchPosition(function(position) {
                    console.log(position)
                    axios({
                        method: 'POST',
                        url: 'https://media.ukraine-inform.com/helps/set-user-position',
                        data: 'user_id=' + (localStorage.getItem('user') || '') + '&latitude='+position.coords.latitude+'&longitude='+position.coords.longitude,
                        headers: {
                            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                        }
                    }).then(res => {
                        console.log(res);
                    });
                }, function(e) {
                    console.log(e)
                }, options);
            }
        }
    },
    methods: {
        returnSelect() {
            if(this.status == 2) {
                this.status = 1;
                this.primary = 'ion-color-primary';
                this.butText = 'Розпочати стрім';
                this.webRTC.stop();
            }
            window.location.href = '/select';
        },
        startStream() {
            if(this.status == 1) {
                this.butText = 'Завершити стрім';
                this.status = 2;
                this.primary = 'ion-color-danger';
                this.webRTC.start(false);
            } else {
                this.status = 1;
                this.butText = 'Розпочати стрім';
                this.primary = 'ion-color-primary';
                window.location.href = '/select';
                this.webRTC.stop();
            }
        },
        startStreamForce() {
            this.butText = 'Завершити стрім';
            this.status = 2;
            this.primary = 'ion-color-danger';
            this.webRTC.start(true);
        },
        nextCam() {
            //this.webRTC.setDevices(this.devices.nextCamera(), this.devices.getAudioDevice());
            this.webRTC.setDevices(null, null);
        },
        getRemote() {
            this.webRTC.getRemoteT();
        },
        startEfir() {
            console.log('start efir');
            this.toEfir = 'to-efir';
            this.efir = true;
            this.winWidth = parseInt(window.innerWidth*0.3);
            this.winHeight = parseInt(window.innerHeight*0.3);
        },
        stopEfir() {
            console.log('stopEfir efir');
            this.toEfir = '';
            this.efir = false;
            this.winWidth = window.innerWidth;
            this.winHeight = window.innerHeight;
        }
    }
}
</script>

<style>
ion-content,
.base-stream {
    background: #141e30;
    background: -webkit-linear-gradient(bottom, #243b55, #141e30);
    background: -moz-linear-gradient(bottom, #243b55, #141e30);
    background: -o-linear-gradient(bottom, #243b55, #141e30);
    background: -ms-linear-gradient(bottom, #243b55, #141e30);
    background: linear-gradient(to top, #243b55, #141e30);
    height: 100%;
    width: 100%;
}
iframe {
    width: 100%;
    height: 100%;
    border: 0;
}
.remote-stream {
    height: 100%;
    display: none;
}
.show.remote-stream {
    display: block;
}
.stream-start ion-button {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
}
.button-native,
.stream-start .button button.button-native {
    border-radius: 0;
}
.control-head {
    position: fixed;
    top: 35px;
    z-index: 999;
    left: 15px;
    font-size: 36px;
    color: #fff;
}
#localInputVideo {
    width: 100%;
}
.to-efir .base-stream {
    width: 30%;
    height: auto;
    position: absolute;
    right: 20px;
    top: 20px;
}
</style>
